<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="">
            <h4 class="title">Slip Man Power</h4>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/data-payroll">Payroll</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link
                    :to="{
                      name: 'DetailManPower',
                      params: {
                        id: formData.id_payroll,
                      },
                    }"
                  >
                    Detail Fee Man Power</router-link
                  >
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ formData.nama_karyawan }}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <!-- <div class="card info-detail">
          <div class="row align-items-center">
            <div class="col-4 text-left">
              <div class="title-invoice">Finanta</div>
              <div class="address">
                {{ formData.alamat }}
              </div>
            </div>
            <div class="title-payroll col-4 text-center">Slip Man Power</div>

            <div class="col-4">
              <div class="row justify-content-end">
                <div class="col-4">
                  <span class="text">Bulan </span>
                </div>

                <div class="col-1">
                  <span style="width: 100px">:</span>
                </div>

                <div class="col-6">
                  <span class="value">{{ formatMonth(formData.bulan) }}</span>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-4">
                  <span class="text">Tanggal </span>
                </div>

                <div class="col-1">
                  <span style="width: 100px">:</span>
                </div>

                <div class="col-6">
                  <span class="value">{{
                    formatDate(formData.tanggal_bayar)
                  }}</span>
                </div>
              </div>

              <div class="row justify-content-end">
                <div class="col-4">
                  <span class="text">No. Referensi </span>
                </div>

                <div class="col-1">
                  <span style="width: 100px">:</span>
                </div>

                <div class="col-6">
                  <span class="value">{{ formData.id }}</span>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-4">
                  <span class="text">Karyawan </span>
                </div>

                <div class="col-1">
                  <span style="width: 100px">:</span>
                </div>

                <div class="col-6">
                  <span class="value">{{ formData.nama_karyawan }}</span>
                </div>
              </div>

              <div class="row justify-content-end">
                <div class="col-4">
                  <span class="text">Project Level </span>
                </div>

                <div class="col-1">
                  <span style="width: 100px">:</span>
                </div>

                <div class="col-6">
                  <span class="value">{{ formData.nama_level }}</span>
                </div>
              </div>
              <div class="d-flex justify-content-start">
                <span class="text">Karyawan :</span>
                <span class="value">Max Verstappen</span>
              </div>
              <div class="d-flex justify-content-start">
                <span class="text">Jabatan :</span>
                <span class="value">UI/UX Design</span>
              </div>
            </div>
          </div>
          <hr />
          <div class="row mt-3">
            <div class="col-6">
              <div class="table-responsive">
                <table class="table table-bordered table-striped" id="tablePemasukan">
                  <thead>
                    <tr>
                      <th style="width: 65%">Pemasukan</th>
                      <th>Jumlah</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, index) in ListItem.pemasukan" :key="index">
                      <td>{{ value.nama }}</td>
                      <td>
                        <span>Rp</span>
                        <span class="float-right">{{
                          formatMoney(value.nilai)
                        }}</span>
                      </td>
                    </tr>
                    <tr v-if="ListItem.pemasukan.length == 0">
                      <td colspan="2" class="text-center">
                        Tidak ada pemasukan
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="table-responsive">
                <table class="table table-bordered table-striped" id="table-total">
                  <tbody>
                    <tr>
                      <td style="width: 65%">Total Pemasukan</td>
                      <td>
                        <span>Rp</span>
                        <span class="float-right">{{
                          formatMoney(ListItem.nilai_pemasukan)
                        }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-md-6">
              <div class="total">
                <div class="row pb-0">
                  <div class="col-8">
                    <div>Total Pemasukan</div>
                  </div>
                  <div class="col-4">
                    <div>
                      <span>Rp</span>
                      <span class="float-right">{{
                        formatMoney(ListItem.nilai_pemasukan)
                      }}</span>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="total-payroll">
                  <div class="row">
                    <div class="col-8">
                      <div>Total diterima</div>
                    </div>
                    <div class="col-4">
                      <div>
                        <span>Rp</span>
                        <span class="float-right">{{
                          formatMoney(ListItem.nilai_pemasukan)
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="total mt-4">
                <div class="total-payroll">
                  <div class="row">
                    <div class="col-12">
                      <div>Terbilang :</div>
                      <div class="mt-2">
                        <div class="mt-2 font-italic">
                          {{
                            `“ ${capitlizeFirst(
                              setTerbilang(ListItem.nilai_pemasukan)
                            )} rupiah ”`
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <div class="card card-detail">
          <div class="d-flex justify-content-between align-items-center">
            <div class="company-wrapper d-flex align-items-center">
              <img
                :src="dataCompany.logo"
                v-if="dataCompany.logo"
                class="mr-3"
                style="width: 90px; height: auto"
              />
              <div class="">
                <div class="title-company">{{ dataCompany.nama }}</div>
                <div class="email-company">{{ dataCompany.email }}</div>
              </div>
            </div>

            <div class="address-company">
              {{ dataCompany.alamat }}
            </div>
          </div>
          <div
            class="d-flex align-items-center justify-content-between mt-5 header-detail"
          >
            <div class="">
              <div class="title-payroll mb-3">MAN POWER SLIP</div>
              <div class="d-flex align-items-center item-detail">
                <div class="text-header">Tanggal bayar</div>
                <div class="text-bold">
                  : {{ formatDate(formData.tanggal_bayar) }}
                </div>
              </div>
            </div>
            <div class="">
              <div class="d-flex align-items-center item-detail">
                <div class="text-header">NAMA</div>
                <div class="text-bold">: {{ formData.nama_karyawan }}</div>
              </div>
              <div class="d-flex align-items-center item-detail">
                <div class="text-header">NIK</div>
                <div class="text-bold">: {{ formData.id_karyawan }}</div>
              </div>
              <div class="d-flex align-items-center item-detail">
                <div class="text-header">REKENING</div>
                <div class="text-bold">
                  : {{ formData.nomor_rekening }} ({{ formData.bank }})
                </div>
              </div>
            </div>
          </div>
          <table class="table table-payroll-slip mt-5">
            <tbody>
              <tr>
                <td class="td-block header-table-blue">Nama project</td>
                <td class="td-block header-table-blue">Level</td>
                <!-- <td class="td-block header-table-blue" style="width: 15%">
                  tanggal
                </td> -->
                <td class="td-block header-table-blue">FRQ</td>
                <td class="td-block header-table-blue">nominal</td>
              </tr>
              <tr v-for="(value, index) in ListItem.pemasukan" :key="index">
                <td class="border-colon-right">
                  {{ value.nama_project }}
                </td>
                <td class="border-colon-right">
                  {{ value.level }}
                </td>
                <!-- <td class="border-colon-right">
                  {{ formatJadwal(value.jadwal) }}
                </td> -->
                <td class="border-colon-right text-center">
                  {{ value.frq }}
                </td>
                <td class="border-colon-right text-right">
                  {{ formatMoney(value.total) }}
                </td>
              </tr>
              <tr>
                <td colspan="3" class="text-right" style="font-weight: bold; font-size: 14px">
                  total FEE MAN POWER
                </td>
                <td class="text-right text-bold">
                  {{ formatMoney(ListItem.nilai_pemasukan) }}
                </td>
              </tr>
              <tr style="border-right: 0; border-left: 0">
                <td colspan="4" style="border-right: 0; border-left: 0"></td>
              </tr>
              <tr>
                <td colspan="4" class="td-block text-center header-table-red">Potongan</td>
              </tr>
              <tr v-for="(value, index) in ListItem.potongan" :key="index"> 
                <td class="border-colon-right" colspan="3">
                  {{ value.nama }}
                </td>
                <td class="border-colon-right text-right">
                  {{ formatMoney(value.nilai) }}
                </td>
              </tr>
              <tr>
                <td colspan="3" class="text-right" style="font-weight: bold; font-size: 14px">
                  total potongan
                </td>
                <td class="text-right text-bold">
                  {{ formatMoney(ListItem.nilai_potongan) }}
                </td>
              </tr>
              <tr style="border-right: 0; border-left: 0">
                <td colspan="4" style="border-right: 0; border-left: 0"></td>
              </tr>
              <tr>
                <td colspan="3" class="td-block text-center header-table-blue">
                  total FEE MAN POWER yang diterima
                </td>
                <td class="text-right text-bold">
                  {{
                    formatMoney(
                      ListItem.nilai_pemasukan - ListItem.nilai_potongan
                    )
                  }}
                </td>
              </tr>
            </tbody>
            <!-- <tr class="border-none">
              <td style="width: 22%"></td>
              <td style="width: 0.5%"></td>
              <td style="width: 5%"></td>
              <td style="width: 5%"></td>
              <td style="width: 21%"></td>
              <td style="width: 0.5%"></td>
              <td style="width: 12%"></td>
              <td style="width: 22%"></td>
              <td style="width: 0.5%"></td>
              <td style="width: 16%"></td>
            </tr>
            <tr class="border-none">
              <td class="border-colon-right">Periode bulan</td>
              <td class="border-colon">:</td>
              <td colspan="3" class="text-bold">
                {{ formatDate(`${formData.bulan}-01`, "MMM YYYY") }}
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr class="border-none">
              <td class="border-colon-right">tgl bayar</td>
              <td class="border-colon">:</td>
              <td colspan="3" class="text-bold">
                {{ formatDate(`${formData.tanggal_bayar}`, "DD MMM YYYY") }}
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr class="border-none">
              <td class="border-colon-right">Nama</td>
              <td class="border-colon">:</td>
              <td colspan="3" class="text-bold">{{ formData.nama_karyawan }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr class="border-none">
              <td class="border-colon-right">NIK</td>
              <td class="border-colon">:</td>
              <td colspan="3" class="text-bold">{{ formData.id_karyawan }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr class="border-none">
              <td class="border-colon-right">DEPARTEMEN/LEVEL</td>
              <td class="border-colon">:</td>
              <td colspan="3" class="text-bold">
                {{ formData.nama_divisi }} / {{ formData.nama_jabatan }}
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr class="border-none">
              <td class="border-colon-right">ACTIVE SINCE</td>
              <td class="border-colon">:</td>
              <td class="text-bold" colspan="2">
                {{ formatDate(formData.tgl_masuk, "DD MMM YYYY") }}
              </td>
              <td></td>
              <td>{{ formatMoney(dateDiff(formData.tgl_masuk, formData.tanggal_bayar)) }} Days</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr> -->
            <!-- <tr class="border-none">
              <td class="border-colon-right">working years</td>
              <td class="border-colon">:</td>
              <td class="text-bold">
                {{ parseFloat(parseFloat(getDescPayroll("masa_kerja")).toFixed(2)) }}
              </td>
              <td></td>
              <td>Years</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr class="border-none">
              <td class="border-colon-right">Married Status</td>
              <td class="border-colon">:</td>
              <td class="text-bold">
                <span v-if="getDescPayroll('jumlah_pasangan') > 0">Menikah</span>
                <span v-else>Belum Menikah</span>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr class="border-none">
              <td class="border-colon-right">Jumlah Anak</td>
              <td class="border-colon">:</td>
              <td class="text-bold">{{ getDescPayroll("jumlah_anak_actual") }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr class="border-none">
              <td class="border-colon-right">Bank Account</td>
              <td class="border-colon">:</td>
              <td class="text-bold" colspan="3">
                {{ formData.nomor_rekening }} ({{ formData.nama_bank }})
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr> -->
          </table>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../../components/Sidebar.vue";
import NavbarDashboard from "../../../../components/Navbar.vue";
import { ref } from "vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

// import $ from 'jquery'
import moment from "moment";
import "moment/locale/id";
import { get_PayrollSlip } from "../../../../actions/payroll";
import { showAlert } from "../../../../helper";
import { DATA } from "../../../../constans/Url";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    SidebarDashboard,
    NavbarDashboard,
  },

  data() {
    return {
      ListItem: {
        pemasukan: [],
        potongan: [],
        subsidi: [],
        nilai_pemasukan: 0,
        nilai_potongan: 0,
        nilai_subsidi: 0,
      },
      DataTable: "",
      selectAll: false,
      dataCompany: {
        logo: "",
        email: "",
        nama: "",
        alamat: "",
      },
      formData: {
        id: "",
        id_payroll: "-",
        kategori: "Gaji",
        bulan: "",
        tanggal_bayar: "",
        keterangan: "",
        akun_perkiraan: "",
        nama_karyawan: "",
        nama_divisi: "",
        nama_jabatan: "",
        alamat: "",
        nama_level: "",
        nomor_rekening: "",
        bank: "",
      },
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  created() {
    this.getData();
  },
  methods: {
    capitlizeFirst(str) {
      // checks for null, undefined and empty string
      if (!str) return;
      return str.match("^[a-z]")
        ? str.charAt(0).toUpperCase() + str.substring(1)
        : str;
    },
    setTerbilang(angka) {
      var bilne = [
        "",
        "satu",
        "dua",
        "tiga",
        "empat",
        "lima",
        "enam",
        "tujuh",
        "delapan",
        "sembilan",
        "sepuluh",
        "sebelas",
      ];

      if (angka < 12) {
        return bilne[angka];
      } else if (angka < 20) {
        return this.setTerbilang(angka - 10) + " belas";
      } else if (angka < 100) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 10)) +
          " puluh " +
          this.setTerbilang(parseInt(angka) % 10)
        );
      } else if (angka < 200) {
        return "seratus " + this.setTerbilang(parseInt(angka) - 100);
      } else if (angka < 1000) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 100)) +
          " ratus " +
          this.setTerbilang(parseInt(angka) % 100)
        );
      } else if (angka < 2000) {
        return "seribu " + this.setTerbilang(parseInt(angka) - 1000);
      } else if (angka < 1000000) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 1000)) +
          " ribu " +
          this.setTerbilang(parseInt(angka) % 1000)
        );
      } else if (angka < 1000000000) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 1000000)) +
          " juta " +
          this.setTerbilang(parseInt(angka) % 1000000)
        );
      } else if (angka < 1000000000000) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 1000000000)) +
          " milyar " +
          this.setTerbilang(parseInt(angka) % 1000000000)
        );
      } else if (angka < 1000000000000000) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 1000000000000)) +
          " trilyun " +
          this.setTerbilang(parseInt(angka) % 1000000000000)
        );
      }
    },

    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatMonth(string) {
      return moment(string + "-01").format("MMMM YYYY");
    },

    formatDate(string, format = "DD MMMM YYYY") {
      return moment(string).format(format);
    },
    formatJadwal(jadwal) {
      if (jadwal) {
        var arr = jadwal.split("|");

        for (const key in arr) {
          if (Object.hasOwnProperty.call(arr, key)) {
            const element = arr[key];
            arr[key] = this.formatDate(element, "DD MMM YYYY");
          }
        }

        return arr.join(", ");
      }
      return "-";
    },

    getData() {
      get_PayrollSlip(
        this.$route.params.id,
        (res) => {
          var { detail, data, company } = res;
          this.formData.id = data.id;
          this.formData.id_payroll = data.id_payroll;
          this.formData.kategori = data.kategori;
          this.formData.bulan = data.bulan;
          this.formData.tanggal_bayar = data.tanggal_bayar;
          this.formData.nama_karyawan = data.nama_karyawan;
          this.formData.id_karyawan = data.id_karyawan;
          this.formData.bank = data.bank;
          this.formData.nomor_rekening = data.nomor_rekening;
          this.formData.alamat = data.alamat_company;
          this.ListItem = detail;

          this.dataCompany = {
            logo: company.logo ? DATA.COMPANY(company.logo) : "",
            nama: company.nama,
            email: company.email,
            alamat: company.alamat,
          };
        },
        () => {
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Data Slip gaji tidak ditemukan",
            showCancelButton: true,
            showConfirmButton: false,
            onCancel: () => {
              this.$router.push({
                name: "DataPayroll",
              });
            },
            onDismiss: () => {
              this.$router.push({
                name: "DataPayroll",
              });
            },
          });
        }
      );
    },
  },
};
</script>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>

<style scoped>
.title-invoice {
  font-weight: 700;
  font-size: 48px;
  background: linear-gradient(102.18deg, #6ec0fe -17.12%, #61efe7 95.5%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.card {
  border: 1px solid #e3e3e3;
  border-radius: 10px;
}

.total-payroll {
  background: #d7ffea;
  border-radius: 0px 0px 4px 4px;
}

.total > .row:first-child {
  line-height: 40px;
}

.total {
  font-size: 14px;
  border: #e3e3e3 1px solid;
  border-radius: 5px;
}

.total .row {
  padding: 15px 25px;
}

#table-total td {
  background-color: #ffd7d7 !important;
  border: none;
  padding: 0px 25px;
  height: 50px;
  /* border-radius: 10px; */
}

#table-total tr td:first-child {
  border-top-left-radius: 10px;
}

#table-total tr td:last-child {
  border-top-right-radius: 10px;
}

.text {
  font-size: 14px;
}

.address {
  width: 400px;
  font-size: 14px;
}

.title-payroll {
  font-size: 37px;
  color: #263238;
  font-weight: 600;
}

.text-bold {
  font-weight: 600;
}

.text-header {
  font-size: 15px;
  text-transform: uppercase;
  width: 152px;
}

.table-payroll-slip tr,
.table-payroll-slip td {
  font-size: 15px;
  border: 1px solid #e3e3e3;
  text-transform: uppercase;
}

/* .table-payroll-slip tr,
  .table-payroll-slip td.border-colon-right {
    border-right-width: 0 !important;
  } */

.table-payroll-slip tr,
.table-payroll-slip td.border-colon {
  border-left: 0 !important;
}

.table-payroll-slip td {
  padding: 14px 12px !important;
  height: 25px !important;
}

.table-payroll-slip td.td-block {
  background-color: #666666;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
}

.table-payroll-slip tr.border-none,
.table-payroll-slip .border-none td {
  border: none !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f5f7f8;
}

.title-company {
  font-weight: 700;
  font-size: 20px;
  color: #0e2c4e;
}

.email-company {
  color: #898989;
  font-size: 16px;
}

.company-wrapper {
  width: 100%;
}

.address-company {
  color: #898989;
  font-size: 16px;
  text-align: right;
  width: 415px;
}

.item-detail {
  margin-bottom: 7px;
}

.header-detail {
  border-radius: 5px;
  border: 1px solid #e3e3e3;
  padding: 20px 30px;
}

.table-payroll-slip td.td-block.header-table-blue {
  background-color: #68caf8;
  color: #000;
  padding: 15px 12px;
}

.table-payroll-slip td.td-block.header-table-red {
  background-color: #ff8d8d;
  color: #000;
  padding: 15px 12px;
}
.title-payroll {
  font-size: 32px;
  color: #263238;
  font-weight: 600;
}
</style>